import React, { FC, useEffect, useState } from 'react';
import { Button, Table, Modal, notification, Select, Input } from 'antd';
import { useGetOnhandIventory } from '../utils/hooks';
import { AuthTokenType, OnhandInventoryProps } from '../utils/types';
import { getAuthToken, getOnhandInventory, getproductinventory } from '../utils/functions';
import AddOnHandInventoryForm from '../components/AddOnHandInventoryForm';
import ContentLayout from '../components/ContentLayout';
import axios from 'axios';
import { onhandUrl } from '../utils/network';
import CloseIcon from '@ant-design/icons/CloseOutlined';
import EditIcon from '@ant-design/icons/EditOutlined';
import SaveIcon from '@ant-design/icons/SaveOutlined';

const { Option } = Select;

const OnHandInventory: FC = () => {

  const [productinvVisible, setProductinvVisible] = useState<boolean>(false);
  const [productinv, setproductinv] = useState<any[]>([]);
  const [prodinvFetching, setprodinvFetching] = useState<boolean>(false);
  const [quickEditMode, setQuickEditMode] = useState<boolean>(false);

  const handleQuickEditSave = async () => {
    const headers = getAuthToken() as AuthTokenType;
  
    // Prepare the data to send
    const updates = onhandinventory.map(item => ({
      id: item.id,
      changedunits: item.changedunits, // New field
      reason: item.reason // New field
    }));
  
    try {
      // Send each update sequentially
      for (const values of updates) {
        await axios.patch(`${onhandUrl}/${values.id}`, values, headers);
      }
  
      notification.success({
        message: 'Inventory Updates Pushed',
      });
      setQuickEditMode(false);
      getOnhandInventory(setOnHandInventory, setFetching);
    } catch (error) {
      notification.error({
        message: 'Quick Edit Save Error',
      });
    }
  };


  const handlenameClick = (productId: number,locationId:number) => {
   
    setProductinvVisible(true);
    getproductinventory(setproductinv,setprodinvFetching, productId,locationId);
  };



  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
       {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
      render: (name: number, record: OnhandInventoryProps) => (
        <a onClick={() => handlenameClick(record.productid, record.locationid)}>{name}</a>
      ),

    },

    {
      title: 'Category',
      dataIndex: 'cat',
      key: 'cat',
    },
    {
      title: 'Sub Category',
      dataIndex: 'subcat',
      key: 'subcat',
    },
    {
      title: 'Storage Location',
      dataIndex: 'storage',
      key: 'storage',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Units on hand',
      dataIndex: 'totalunits',
      key: 'totalunits',
    },

    ...(quickEditMode
      ? [
          {
            title: 'Changed Units',
            dataIndex: 'changedunits',
            key: 'changedunits',
            render: (text: number, record: OnhandInventoryProps) => (
              <Input
              style={{ width: 100, }}   
              type="number"
                value={text}
                onChange={(e) => {
                  const newValue = parseFloat(e.target.value);
                  setOnHandInventory(prev => prev.map(item => 
                    item.id === record.id ? { ...item, changedunits: newValue } : item
                  ));
                }}
              />
            ),
          },
          {
            title: 'Reason',
            dataIndex: 'reason',            
            key: 'reason',
            render: (text: string, record: OnhandInventoryProps) => (
              <Select
              style={{ width: 100, }} 
                value={text}
                onChange={(value) => {
                  setOnHandInventory(prev => prev.map(item => 
                    item.id === record.id ? { ...item, reason: value } : item
                  ));
                }}
              >
                <Option value="Stock Received">Stock Received</Option>
                <Option value="Inventory Recount">Inventory Recount</Option>
                <Option value="Damage">Damage</Option>
                <Option value="Theft">Theft</Option>
                <Option value="Loss">Loss</Option>
                <Option value="Restock Return">Restock Return</Option>
              </Select>
            ),
          }
        ]
      : []
    ),
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: OnhandInventoryProps) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },


  ];

  const [onhandinventory, setOnHandInventory] = useState<OnhandInventoryProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingInventory, seteditingInventory] = useState<OnhandInventoryProps | null>(null);

  useGetOnhandIventory(setOnHandInventory, setFetching);

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onSuccessAddOnHandInventory = () => {
    setDrawerVisible(false);
    getOnhandInventory(setOnHandInventory, setFetching); // Refresh on-hand inventory list
  };



  useEffect(() => {
    // When editingCategory changes, open the modal
    if (editingInventory) {
      setDrawerVisible(true);
    }
  }, [editingInventory]);

  const onCloseWithoutEditing = () => {
    seteditingInventory(null); // Reset editingCategory when form is closed without editing
  };

  const handleEdit = (record: OnhandInventoryProps) => {
    seteditingInventory(record);
  };
  const handleDelete = (inventoryId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this category?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a category
          await axios.delete(`${onhandUrl}/${inventoryId}`, headers);
  
          // After successful deletion, fetch updated categories
          getOnhandInventory(setOnHandInventory, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };



  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = onhandinventory.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );


  return (
    <>
      <div className="card">
        <div className="cardHeader">
          <h2 className="headContent">Inventory</h2>
          <div className="rightContent">
            <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '5px', height: 40 }}
            />
                         <Button onClick={() => setDrawerVisible(true)}>Add Inventory</Button>

            <Button
              className='bbutton'
              onClick={() => setQuickEditMode(!quickEditMode)}
              style={{ marginRight: '5px' }}
            >
              {quickEditMode ? <CloseIcon /> : <EditIcon />}
            </Button>
            {quickEditMode && (
              <Button
                className='bbutton'
                onClick={handleQuickEditSave}
                style={{ marginRight: '5px' }}
              >
                <SaveIcon />
              </Button>
            )}
          </div>
        </div>
        <Table
          className="custom-table"
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          loading={fetching}
        />
      </div>

      <Modal
        visible={productinvVisible}
        onCancel={() => {
          setProductinvVisible(false);
          setproductinv([]);
        }}
        footer={null}
      >
        {prodinvFetching ? (
          <p>Loading...</p>
        ) : (
          <Table
            className="custom-table"
            dataSource={productinv}
            columns={[
              {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: (dateString: string) => {
                  const date = new Date(dateString);
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const day = String(date.getDate()).padStart(2, '0');
                  return `${year}-${month}-${day}`;
                },
              },
              {
                title: 'Starting Inventory',
                dataIndex: 'previous_value',
                key: 'previous_value',
              },
              {
                title: 'Ending Inventory',
                dataIndex: 'inventory_level',
                key: 'inventory_level',
              },
              {
                title: 'Reason',
                dataIndex: 'reason',
                key: 'reason',
              },
            ]}
            rowKey="id"
          />
        )}
      </Modal>
      
      <AddOnHandInventoryForm
        isVisible={drawerVisible}
        onClose={onCloseDrawer}
        onSuccessCallBack={onSuccessAddOnHandInventory}
        editingInventory={editingInventory}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </>
  );
};

export default OnHandInventory;